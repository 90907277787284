import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

/* public variables */
var _y = 41, _x = 61;
var _previousGeneration = [],
// eslint-disable-next-line
_pullbackGeneration = [],
_currentGeneration = [];
var _extinct = false;
var _wasAlive = false;
var _updateTopScore = false;

/* utils */
function GetResponse(val) {
  if(val === '"Ok"')
    SetGlobalTopScorer();
}
function SetTopScore(topScore) {
  if(topScore > 0) {
    SetCookie('topscore', topScore, 30);
  }
}
function SetCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function DeleteCookie(cname) {
  if(GetCookie(cname) !== "")
    SetCookie(cname, '', -1);
}
function GetCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function HttpGetAsync(theUrl, callback) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function() {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
            callback(xmlHttp.responseText);
    }
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.send(null);
}
function SetGlobalTopScorer() {
  HttpGetAsync('http://pbrecordings.azurewebsites.net/gol/topscorer', HandleSetGlobalTopScorer);
}
function HandleSetGlobalTopScorer(val) {
  let globalTopScorer = 'Not set'
  let globalTopScore = 0;

  if(val !== '' && val !== undefined && val !== '""') {
    let temp = val.replace('"', '').replace('\\r', '').replace(';', ' ').replace('"', '').split(' ');
    let user = temp[2];
    let score = temp[3];
    globalTopScore = parseInt(score);
    globalTopScorer = (' ' + score + ' by ' + user);
  }

  window.engine.setState({
    globalTopScorer: globalTopScorer,
    globalTopScore: globalTopScore
  });
}

/* engine */
function GetStartArray(val) {
    if(val === '0'){
      return PopulateGlider();
    }
    else if(val === '1'){
      return PopulateSmallExploder();
    }
    else if(val === '2'){
      return PopulateExploder();
    }
    else if(val === '3'){
      return Populate10CellRow();
    }
    else if(val === '4'){
      return PopulateLightweightSpaceship();
    }
    else if(val === '5'){
      return PopulateTumbler();
    }
    else if(val === '6'){
      return PopulateGosperGliderGun();
    }

    return RenderDefaultArr(_y, _x, false);
}
function Sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
function Alive(_i, _p) {
    let count = 0;
    for (let i = _i - 1; i < _i + 2; i++) {
        let tempI = i;
        if (i < 0)
            tempI = _y + i;
        if (i >= _y)
            tempI = i - _y;
        for (let p = _p - 1; p < _p + 2; p++) {
            let tempP = p;
            if (i === _i && p === _p)
                continue;
            if (p < 0)
                tempP = _x + p;
            if (p >= _x)
                tempP = p - _x;
            if (_previousGeneration[tempI][tempP])
                count++;
        }
        if (count > 3) {
            _currentGeneration[_i][_p] = false;
            return _currentGeneration[_i][_p];
        }
    }
    if (count === 3)
        _currentGeneration[_i][_p] = true;
    else if (count === 2 && _previousGeneration[_i][_p])
        _currentGeneration[_i][_p] = true;
    else
        _currentGeneration[_i][_p] = false;
    return _currentGeneration[_i][_p];
}
function InitLife(startArr) {
    _extinct = false;
    _previousGeneration = startArr;
    //_pullbackGeneration = _previousGeneration.slice();
}
function AsTimeGoesBy() {
    let count = 0;
    let wasAlive = false;
    _currentGeneration = RenderDefaultArr(_y, _x, false);
    //_pullbackGeneration = _previousGeneration.slice();

    for (let i = 0; i < _y; i++) {
      for (let p = 0; p < _x; p++) {
        if(!wasAlive && _previousGeneration[i][p])
          wasAlive = true;
        if (Alive(i, p))
          count++;
      }
    }

    _wasAlive = wasAlive;

    _previousGeneration = _currentGeneration.slice();

    _extinct = count < 1 ? true : false;
    return _currentGeneration;
}
function RenderDefaultArr(y, x, value) {
  let ys = [];
  for (let i = 0; i < y; i++) {
    let xs = [];
    for (let j = 0; j < x; j++) {
      xs.push(value);
    }
    ys.push(xs);
  }
  return ys;
}
function PopulateGlider() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  squares[centerY - 1][centerX] = true;
  squares[centerY][centerX + 1] = true;
  squares[centerY + 1][centerX - 1] = true;
  squares[centerY + 1][centerX] = true;
  squares[centerY + 1][centerX + 1] = true;
  return squares;
}
function PopulateSmallExploder() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  squares[centerY - 1][centerX] = true;
  squares[centerY][centerX - 1] = true;
  squares[centerY][centerX] = true;
  squares[centerY][centerX + 1] = true;
  squares[centerY + 1][centerX - 1] = true;
  squares[centerY + 1][centerX + 1] = true;
  squares[centerY + 2][centerX] = true;
  return squares;
}
function PopulateExploder() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  squares[centerY - 2][centerX - 2] = true;
  squares[centerY - 2][centerX] = true;
  squares[centerY - 2][centerX + 2] = true;
  squares[centerY - 1][centerX - 2] = true;
  squares[centerY - 1][centerX + 2] = true;
  squares[centerY][centerX - 2] = true;
  squares[centerY][centerX + 2] = true;
  squares[centerY + 1][centerX - 2] = true;
  squares[centerY + 1][centerX + 2] = true;
  squares[centerY + 2][centerX - 2] = true;
  squares[centerY + 2][centerX] = true;
  squares[centerY + 2][centerX + 2] = true;
  return squares;
}
function Populate10CellRow() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  for (let i = centerX - 5; i < centerX + 5; i++) {
    squares[centerY][i] = true;
  }
  return squares;
}
function PopulateLightweightSpaceship() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  squares[centerY - 1][centerX - 1] = true;
  squares[centerY - 1][centerX] = true;
  squares[centerY - 1][centerX + 1] = true;
  squares[centerY - 1][centerX + 2] = true;
  squares[centerY][centerX - 2] = true;
  squares[centerY][centerX + 2] = true;
  squares[centerY + 1][centerX + 2] = true;
  squares[centerY + 2][centerX - 2] = true;
  squares[centerY + 2][centerX + 1] = true;

  return squares;
}
function PopulateTumbler() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  squares[centerY - 2][centerX - 2] = true;
  squares[centerY - 2][centerX - 1] = true;
  squares[centerY - 2][centerX + 1] = true;
  squares[centerY - 2][centerX + 2] = true;

  squares[centerY - 1][centerX - 2] = true;
  squares[centerY - 1][centerX - 1] = true;
  squares[centerY - 1][centerX + 1] = true;
  squares[centerY - 1][centerX + 2] = true;

  squares[centerY][centerX - 1] = true;
  squares[centerY][centerX + 1] = true;

  squares[centerY + 1][centerX - 3] = true;
  squares[centerY + 1][centerX - 1] = true;
  squares[centerY + 1][centerX + 1] = true;
  squares[centerY + 1][centerX + 3] = true;

  squares[centerY + 2][centerX - 3] = true;
  squares[centerY + 2][centerX - 1] = true;
  squares[centerY + 2][centerX + 1] = true;
  squares[centerY + 2][centerX + 3] = true;

  squares[centerY + 3][centerX - 3] = true;
  squares[centerY + 3][centerX - 2] = true;
  squares[centerY + 3][centerX + 2] = true;
  squares[centerY + 3][centerX + 3] = true;
  return squares;
}
function PopulateGosperGliderGun() {
  const squares = RenderDefaultArr(_y, _x, false);
  let centerY = Math.ceil(_y / 2) - 1;
  let centerX = Math.ceil(_x / 2) - 1;

  // square
  squares[centerY - 3][centerX - 19] = true;
  squares[centerY - 3][centerX - 18] = true;
  squares[centerY - 2][centerX - 19] = true;
  squares[centerY - 2][centerX - 18] = true;

  squares[centerY - 5][centerX + 15] = true;
  squares[centerY - 5][centerX + 16] = true;
  squares[centerY - 4][centerX + 15] = true;
  squares[centerY - 4][centerX + 16] = true;

  // dot
  squares[centerY - 3][centerX - 10] = true;
  squares[centerY - 3][centerX - 9] = true;
  squares[centerY - 2][centerX - 11] = true;
  squares[centerY - 2][centerX - 9] = true;
  squares[centerY - 1][centerX - 11] = true;
  squares[centerY - 1][centerX - 10] = true;

  squares[centerY - 5][centerX + 4] = true;
  squares[centerY - 5][centerX + 5] = true;
  squares[centerY - 4][centerX + 3] = true;
  squares[centerY - 4][centerX + 5] = true;
  squares[centerY - 3][centerX + 3] = true;
  squares[centerY - 3][centerX + 4] = true;

  // glider
  squares[centerY - 1][centerX - 3] = true;
  squares[centerY - 1][centerX - 2] = true;
  squares[centerY][centerX - 3] = true;
  squares[centerY][centerX - 1] = true;
  squares[centerY + 1][centerX - 3] = true;

  squares[centerY + 2][centerX + 16] = true;
  squares[centerY + 2][centerX + 17] = true;
  squares[centerY + 3][centerX + 16] = true;
  squares[centerY + 3][centerX + 18] = true;
  squares[centerY + 4][centerX + 16] = true;

  squares[centerY + 7][centerX + 5] = true;
  squares[centerY + 7][centerX + 6] = true;
  squares[centerY + 7][centerX + 7] = true;
  squares[centerY + 8][centerX + 5] = true;
  squares[centerY + 9][centerX + 6] = true;

  return squares;
}

/* elements */
function Options() {
  let opts = [<option key="0" value="0">Glider</option>,
  <option key="1" value="1">Small Exploder</option>,
  <option key="2" value="2">Exploder</option>];
  if(_x > 9) {
    opts.push(<option key="3" value="3">10 Cell Row</option>);
  }
  opts.push(<option key="4" value="4">Lightweight Spaceship</option>);
  opts.push(<option key="5" value="5">Tumbler</option>);
  if(_x > 39) {
    opts.push(<option key="6" value="6">Gosper Glider Gun</option>);
  }
  return opts;
}
function Select(props) {
  return (
    <select onChange={props.onChange}>
      {Options()}
    </select>
  );
}
function Square(props) {
  return (
    <button className={props.value} onClick={props.onClick}></button>
  );
}
function Reset(props) {
  return (
    <button className="reset" onClick={props.onClick}>
      Reset
    </button>
  );
}
function ResetTopScore(props) {
  return (
    <button onClick={props.onClick}>
      Reset top score
    </button>
  );
}
function Start(props) {
  return (
    <button className="start" onClick={props.onClick}>
      {props.value}
    </button>
  );
}
function Step(props) {
  return (
    <button className="step" onClick={props.onClick}>
      Step by step
    </button>
  );
}
function HiddenForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <br/>
      <div>&nbsp; Well done! Type username to save your beating of global top score &nbsp;
      <input type="text" placeholder="username" value={props.value} onChange={props.onChange} />&nbsp;
      <input type="submit" value="Save"/>&nbsp;
      <input type="button" value="Cancel" onClick={props.onClick}/>
      </div>
      <br/>
    </form>
  );
}

/* components */
class Board extends React.Component {
  constructor(props){
    super(props);

    if(window.screen.width < 1280){
      _x = Math.ceil(window.screen.width / 16) - 1;
      _y = (_x * 2) - 10;
    }

    let c = GetCookie('topscore');
    let username = GetCookie('username');
    let topScore = parseInt(c === '' ? '0': c);

    this.state = {
      squares: GetStartArray('0'),
      started: false,
      counter: 0,
      topScore: topScore,
      username: username,
      globalTopScorer: '',
      globalTopScore: -1,
      forceHide: false
    };

    SetGlobalTopScorer();
  }

  runStep(initial){
    let squares = AsTimeGoesBy();
    let started = initial || this.state.started;
    let counter = _wasAlive ? (this.state.counter + 1) : this.state.counter;

    this.setState({
      squares: squares,
      started: started,
      counter: counter
    });
  }
  runGame(initial){
    if(initial || (!_extinct && this.state.started)){
        this.runStep(initial);
        Sleep(500).then(() => {
          this.runGame(false);
        });
      }
      else{
        this.setState({
          started: false
        });
      }
  }

  handleClick(i, j) {
    if(this.state.started)
      return;

    _updateTopScore = true;
    const squares = this.state.squares.slice();

    squares[i][j] = !squares[i][j];
    this.setState({
      squares: squares,
      counter: 0
    });
  }
  handleReset() {
    _extinct = false;
    let topScore = this.state.counter > this.state.topScore && _updateTopScore ? this.state.counter : this.state.topScore;

    if(topScore !== this.state.topScore)
      SetTopScore(topScore);

    this.setState({
      squares: RenderDefaultArr(_y, _x, false),
      started: false,
      counter: 0,
      topScore: topScore
    });
  }
  handleResetTopScore() {
    DeleteCookie('topscore');

    this.setState({
      topScore: 0
    });
  }
  handleChange(event) {
      let val = event.target.value;

      _extinct = false;
      _updateTopScore = false;
      const squares = GetStartArray(val);
      const started = false;

      this.setState({
        squares: squares,
        started: started,
        counter: 0
      });
  }
  handleStart(){
    const squares = this.state.squares.slice();
    const started = this.state.started;

    if(!started){
      InitLife(squares);
      this.runGame(true);
    }
    else{
      let topScore = this.state.counter > this.state.topScore && _updateTopScore ? this.state.counter : this.state.topScore;

      if(topScore !== this.state.topScore)
        SetTopScore(topScore);

      this.setState({
        squares: squares,
        started: !started,
        topScore: topScore
      });
    }
  }
  handleStep() {
    const squares = this.state.squares.slice();
    const started = this.state.started;

    if(!started){
      InitLife(squares);
    }
    this.runStep(false);
  }
  handleUsername(event) {
    let username = event.target.value;

    this.setState({
      username: username
    });
  }
  handleSubmit(event) {
    if(this.state.username.length > 0) {
      SetCookie('username', this.state.username, 30);
      let url = 'http://pbrecordings.azurewebsites.net/gol/updatetopscorer?username='
      + this.state.username + '&score='
      + this.state.topScore;
      HttpGetAsync(url, GetResponse);
    }
    event.preventDefault();
  }
  handleCancel() {
    let forceHide = true;

    this.setState({
      forceHide: forceHide
    });
  }

  renderSquare(i, j) {
    return (
      <Square
        key={'cell' + i.toString() + j.toString()}
        value={this.state.squares[i][j] === true ? 'square clicked' : 'square'}
        onClick={() => this.handleClick(i, j)}
      />
    );
  }
  renderReset() {
    return (
      <Reset
      onClick={() => this.handleReset()}
      />
    );
  }
  renderResetTopScore() {
    return (
      <ResetTopScore
      onClick={() => this.handleResetTopScore()}
      />
    );
  }
  renderSelect() {
    return (
      <Select
      onChange={this.handleChange.bind(this)}
      />
    );
  }
  renderStart() {
    return (
      <Start
      value={this.state.started ? 'Stop' : 'Start'}
      onClick={() => this.handleStart()}
      />
    );
  }
  renderStep() {
    return (
      <Step
      onClick={() => this.handleStep()}
      />
    );
  }
  renderHiddenForm() {
    if(this.state.topScore > this.state.globalTopScore && this.state.globalTopScore > -1 && !this.state.forceHide)
      return (
        <HiddenForm
        key={'hiddenForm'}
        value={this.state.username}
        onChange={this.handleUsername.bind(this)}
        onSubmit={this.handleSubmit.bind(this)}
        onClick={() => this.handleCancel()}
        />
      );
    else
      return (null);
  }

  createTable = () => {
      let table = [];
      let middle = Math.ceil(_y / 2);
      // Outer loop to create parent
      for (let i = 0; i < _y; i++) {
        let children = [];
        for (let j = 0; j < _x; j++) {
          children.push(this.renderSquare(i, j));
        }
        //Create the parent and add the children
        table.push(<div key={'row' + i.toString()} className="board-row">{children}</div>);
        if(i === middle) {
          table.push(this.renderHiddenForm());
        }
      }
      return table;
    }
  render() {
    let title = 'Game of life';
    let counter = this.state.counter.toString();
    let topScore = this.state.topScore.toString();
    let globalTopScorer = this.state.globalTopScorer;

    return (
      <div>
        <div className="title">{title}</div>
        <div className="inline">
          <div className="pull-right">Top score: {topScore}</div>
          <div className="globalTopScore">Global top score: {globalTopScorer}</div>
        </div>
        {this.createTable()}
        <div className="inline">
          <div className="pull-right">Cycles: {counter}</div>
          <div className="select">{this.renderSelect()}</div>
          <div className="start">{this.renderStart()}</div>
          <div className="step">{this.renderStep()}</div>
          <div className="reset">{this.renderReset()}</div>
          <div className="resetTopScore">{this.renderResetTopScore()}</div>
        </div>
      </div>
    );
  }
}
class Game extends React.Component {
  render() {
    return (
      <div className="game">
        <div className="game-board">
          <Board ref={(engine) => {window.engine = engine}}/>
        </div>
      </div>
    );
  }
}

/* ======================================== */

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);
